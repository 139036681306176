import { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { SettingsContentProps } from '../SettingsContainer';
import { updateUserNotifications, useGetUserNotifications } from './UserNotificationSettingsHttpServices';
import { useStore } from '../../../useStore';
import { NotificationSettingOption, UserNotificationGroup, UserNotificationSetting } from '../../../utils/interface';
import Accordion from '../../../components/Accordian/Accordion';
import UserNotificationModuleOption from './UserNotificationModuleOption';
import Button from '../../../components/Button/Button';
import { useMutation } from 'react-query';
import FormSuccess from '../../../components/FormsPages/FormSuccess/FormSuccess';
import Loader from '../../../components/Loader/Loader';
import { emailOptIn, emailOptOut, useGetIsOptedOut } from '../../../customHooks/UserHttpServices';
import { queryKeys } from '../../../react-query/queryKeys';
import { queryClient } from '../../../react-query/useQueryClientGet';
import Alert from '../../../components/Alert/Alert';
import useHandleBlocker from '../../../customHooks/useHandleBlocker';

const UserNotificationSettingsBody: FunctionComponent<SettingsContentProps> = ({ module, modules, notificationOptions }) => {
  let defaultPanelIndex = 'Plant Information';
  const [baseOrgId, setBaseOrgId] = useState<number>(0);
  const [canSave, setCanSave] = useState<boolean>(false);
  const [optOutChecked, setOptOutChecked] = useState<boolean>(false);
  const [openedPanelIndex, setOpenedPanelIndex] = useState<number | string>(-1);
  const [currentPanelIndex, setCurrentPanelIndex] = useState<number | string>(-1);
  const [notificationGroups, setNotificationGroups] = useState<Array<UserNotificationGroup>>([]);
  const [userNotificationSettings, setUserNotificationSettings] = useState<Array<UserNotificationSetting>>([]);
  const [userNotificationFormSettings, setUserNotificationFormSettings] = useState<Array<UserNotificationSetting>>([]);
  const { selectedPlant, setShowError } = useStore();
  const [successMessage, setSuccessMessage] = useState<string | ReactElement>();
  const { setIsBlocked } = useHandleBlocker();
  useEffect(() => {
    if (selectedPlant.baseOrgId) {
      setBaseOrgId(selectedPlant.baseOrgId);
    }
  }, [selectedPlant.baseOrgId]);

  useEffect(() => {
    setOpenedPanelIndex(currentPanelIndex);
  }, [currentPanelIndex]);

  const {
    refetch: refetchNotificationOptions,
    isLoading,
    isFetching: isFetchingNotificationOptions
  } = useGetUserNotifications(baseOrgId, {
    enabled: baseOrgId > 0,
    onSuccess(data) {
      setUserNotificationSettings(data?.data || []);
      setUserNotificationFormSettings(data?.data || []);
      setOpenedPanelIndex(defaultPanelIndex);
      setCurrentPanelIndex(defaultPanelIndex);
    },
    onError(err: unknown) {
      setShowError?.({
        isError: true,
        title: 'An error occured while fetching notification options.',
        ErrorType: 'danger'
      });
    }
  });

  const {
    mutate: UpdateUserNotiofications,
    isSuccess: isUpdated,
    reset: resetUpdateMutation
  } = useMutation(updateUserNotifications, {
    onSuccess: () => {
      refetchNotificationOptions();
    },
    onError: (error: unknown) => {
      setShowError?.({
        isError: true,
        title: 'An error occured while updating notification settings. Please try again.',
        ErrorType: 'danger'
      });
    }
  });

  useEffect(() => {
    const checkTriggerChanges = JSON.stringify(userNotificationSettings) !== JSON.stringify(userNotificationFormSettings);
    setCanSave(checkTriggerChanges);
    setIsBlocked(checkTriggerChanges);
  }, [userNotificationSettings, userNotificationFormSettings, setIsBlocked]);

  const handleUpdateUserNotifications = async () => {
    UpdateUserNotiofications({ baseOrgId: baseOrgId, userNotifications: userNotificationFormSettings });
  };

  const handleCancelChanges = () => {
    setOpenedPanelIndex(defaultPanelIndex);
    setCurrentPanelIndex(defaultPanelIndex);
    setUserNotificationFormSettings(userNotificationSettings);
    executeScroll();
  };

  const handleOptOutChange = (optOut: boolean) => {
    setOptOutChecked(optOut);
    if (optOut) {
      optOutOfEmails({});
    } else {
      optInEmails({});
    }
  };

  const formatUserNotificationGroups = (notificationOptions: NotificationSettingOption[]) => {
    const formatNotificationGroups: UserNotificationGroup[] = [
      ...new Map(
        notificationOptions
          ?.sort((a, b) => (a.parentModuleId > b.parentModuleId ? 1 : -1))
          ?.map((item) => {
            let childGroups = notificationOptions.filter((trigger) => trigger.parentModuleId === item.parentModuleId);

            let childNotifications = childGroups
              ?.sort((a, b) => (a.moduleId > b.moduleId ? 1 : -1))
              ?.reduce((childmodule: any, mod: NotificationSettingOption) => {
                if (!item.parentModuleId) {
                  item.parentModuleId = item.moduleId;
                  item.parentModuleName = item.moduleName;

                  (childmodule[item.moduleName] = childmodule[item.moduleName] || []).push(mod);
                }
                (childmodule[mod.moduleName ?? 'Other'] = childmodule[mod.moduleName ?? 'Other'] || []).push(mod);

                return childmodule;
              }, {});

            return [item['parentModuleId'], { id: item.parentModuleId, name: item.parentModuleName, modules: childNotifications }];
          })
      ).values()
    ]
      .filter((item) => item.id > 0)
      ?.sort((a, b) => (a.id > b.id ? 1 : -1));

    setNotificationGroups(formatNotificationGroups);
  };

  useEffect(() => {
    if (notificationOptions) {
      formatUserNotificationGroups(notificationOptions);
    }
  }, [notificationOptions]);

  const executeScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const { mutate: optOutOfEmails } = useMutation(emailOptOut, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.accountSettings);
      refetch();
      setSuccessMessage(<>You have successfully opted out of receiving all email alerts for all plants you are associated to.</>);
    }
  });

  const { mutate: optInEmails } = useMutation(emailOptIn, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.accountSettings);
      refetch();
      setSuccessMessage(<>You have successfully opted in to receive all email alerts for all plants you are associated to.</>);
    }
  });

  const {
    data: isOptedOut,
    isFetched: isFetchedOptOut,
    refetch
  } = useGetIsOptedOut({
    enabled: true
  });

  useEffect(() => {
    if (isFetchedOptOut) {
      setOptOutChecked(isOptedOut || false);
    }
  }, [isFetchedOptOut, isOptedOut]);

  return (
    <div className="flex col flex-1">
      {successMessage && (
        <div className="mb-2">
          <Alert
            type="success"
            onClose={() => setSuccessMessage(undefined)}
          >
            <span>{successMessage}</span>
          </Alert>
        </div>
      )}
      {isUpdated && (
        <FormSuccess
          modaltitlename={'Changes Saved'}
          modaltitle={''}
          comment={`In-App Notification and Email Alert Settings have been successfully updated.`}
          isTitleCenter={true}
          modalSize={'sm'}
          button_done={true}
          buttonDoneOnclick={() => {
            resetUpdateMutation();
            executeScroll();
          }}
          button_addmore={false}
        />
      )}

      {isFetchedOptOut && isOptedOut !== undefined && (
        <>
          <h3 className="flex flex-row mb-1 font-bold text-xl text-dark-blue">Email Opt Out</h3>
          <div className="flex flex-row mb-3 font-bold">
            Please Note: Email opt-out is a global setting and will turn off email alerts for all plants assigned to you.
          </div>
          <div className="flex flex-1 justify-start items-center gap-3">
            <div className="font-normal">Email Notification Opt-Out:</div>
            <input
              type="checkbox"
              checked={optOutChecked}
              onChange={(e) => handleOptOutChange(e.target.checked)}
            />
          </div>
          <hr />
        </>
      )}

      {isFetchingNotificationOptions || isLoading ? (
        <Loader />
      ) : (
        userNotificationSettings &&
        notificationGroups && (
          <>
            <h3 className="flex flex-row mb-1 font-bold text-xl text-dark-blue">
              Manage which Email Notifications you receive for Plant: {selectedPlant.name}
            </h3>
            <div className="flex flex-row mb-3 font-bold">
              Please Note: These settings are plant specific and will only be applied to the currently selected plant.
            </div>

            <div className="flex flex-row gap-3 mt-7">
              <Button
                className="darkBlue"
                text={'Save Changes'}
                disabled={!canSave}
                onClick={handleUpdateUserNotifications}
              />
              <Button
                className="whiteBtn"
                text={'Cancel'}
                disabled={!canSave}
                onClick={handleCancelChanges}
              />
            </div>
            <hr />
            {notificationGroups.map((group, groupIndex) => (
              <div key={groupIndex}>
                <>
                  <div className={`flex flex-row font-bold text-lg text-dark-blue ${groupIndex === 0 ? '' : 'mt-10'}`}>{group.name}</div>

                  {Object.keys(group.modules).map((module, moduleIndex) => {
                    return (
                      <div key={module}>
                        <Accordion
                          isDeleted={false}
                          titleContent={`${module}`}
                          isOpen={openedPanelIndex === module}
                          toggleOpen={() => setOpenedPanelIndex(openedPanelIndex !== module ? module : -1)}
                          headerStyle={{ borderRadius: 4, marginTop: 20, paddingLeft: 20, fontWeight: 'bold' }}
                          contentStyle={{}}
                        >
                          <UserNotificationModuleOption
                            moduleNotifications={
                              (userNotificationFormSettings && userNotificationFormSettings.filter((t) => t.moduleName === module)) || []
                            }
                            userNotifications={userNotificationFormSettings || []}
                            setUserNotifications={setUserNotificationFormSettings}
                            isEmailOptedOut={optOutChecked}
                          />
                        </Accordion>
                      </div>
                    );
                  })}
                </>
              </div>
            ))}
            <hr />
            <div className="flex flex-row gap-3 mt-3">
              <Button
                className="darkBlue"
                text={'Save Changes'}
                disabled={!canSave}
                onClick={handleUpdateUserNotifications}
              />
              <Button
                className="whiteBtn"
                text={'Cancel'}
                disabled={!canSave}
                onClick={handleCancelChanges}
              />
            </div>
          </>
        )
      )}
    </div>
  );
};

export default UserNotificationSettingsBody;
