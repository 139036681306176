import ImageImports from '../../../../utils/ImageImports';
import Button from '../../../../components/Button/Button';
import { useNavigate } from 'react-router-dom';

function RoleConfigurationHeader() {
  const { settings } = ImageImports;
  const navigate = useNavigate();

  return (
    <div className="flex flex-row items-start gap-6 self-stretch">
      <div className="flex flex-col items-start gap-2 grow">
        <span className="font-bold text-2xl text-wrap">Customer Portal Role Definitions</span>
        <span className="text-base">Configure the permissions each role has for each Customer Portal site sub-module.</span>
      </div>
      <div className="flex flex-row items-start whitespace-nowrap">
        <Button
          preImg={
            <img
              src={settings}
              style={{ filter: 'var(--svgFilterWhite)' }}
              alt=""
            />
          }
          className={`darkBlue`}
          text="Manage Roles"
          onClick={() => navigate('/administration/role-configuration/manage-roles')}
          textClassName={`text-nowrap`}
          style={{ height: 36 }}
        />
      </div>
    </div>
  );
}

export default RoleConfigurationHeader;
