import React, { FunctionComponent, ReactElement, CSSProperties } from 'react';
// import { Link } from 'react-router-dom';
import './Button.css';

interface buttonProps {
  className: string;
  style?: CSSProperties;
  textClassName?: string;
  text: string | ReactElement;
  preImg?: ReactElement;
  img?: ReactElement;
  disabled?: boolean;
  onClick?: () => void;
  type?: 'button' | 'submit';
}

const Button: FunctionComponent<buttonProps> = (props: buttonProps) => {
  return (
    <button
      disabled={props.disabled}
      type={props.type}
      className={'primaryBtn ' + props.className}
      style={{ ...props.style }}
      onClick={(e) => {
        e.preventDefault();
        props.onClick && props.onClick();
      }}
    >
      <div className="flex flex-row justify-center items-center content-center gap-2">
        {props.preImg}
        <span className={props.textClassName && props.textClassName}>{props.text}</span>
        {props.img}
      </div>
    </button>
  );
};

Button.defaultProps = {
  type: 'button'
};

export default Button;
