import { FunctionComponent } from 'react';
import tableClasses from '../../../components/Tables/DisplayLikeTable/DisplayLikeTable.module.css';
import { UserNotificationSetting } from '../../../utils/interface';
import classes from './UserNotificationModuleOption.module.css';

export interface UserNotificationModuleOptionProps {
  moduleNotifications: UserNotificationSetting[];
  userNotifications: UserNotificationSetting[];
  setUserNotifications: React.Dispatch<React.SetStateAction<Array<UserNotificationSetting>>>;
  isEmailOptedOut: boolean;
}

const UserNotificationModuleOption: FunctionComponent<UserNotificationModuleOptionProps> = ({
  moduleNotifications,
  userNotifications,
  setUserNotifications,
  isEmailOptedOut
}) => {
  const handleChange = (newTriggerValue: boolean, targetProperty: string, updatedTrigger: UserNotificationSetting) => {
    setUserNotifications(
      userNotifications.map((item) =>
        item.id === updatedTrigger.id ? { ...item, [targetProperty]: newTriggerValue } : item
      )
    );
  };

  const ucFirst = (str: string = '') => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const formatOperationType = (operationType: string) => {
    let opType = operationType;
    switch (operationType.toLocaleLowerCase()) {
      case 'delete':
        opType = 'deleted';
        return ucFirst(opType);
      case 'reply':
        opType = 'replied';
        return ucFirst(opType);
      case 'add':
        opType = 'added';
        return ucFirst(opType);
      case 'edit':
        opType = 'edited';
        return ucFirst(opType);
      default:
        opType = operationType;
        return ucFirst(opType);
    }
  };

  return (
    <div className={`${tableClasses.table} ${classes.triggers}`}>
      <div>
        <div className="flex items-center">
          <div className="text-wrap break-words">{`Notification Type:`}</div>
          <div className="text-wrap break-words">Email Notifications</div>
        </div>
      </div>
      <div>
        {moduleNotifications
          .filter((x) => x.notificationTriggerEditable === true || x.emailTriggerEditable === true)
          ?.sort((a, b) => (a.operationTypeId > b.operationTypeId ? 1 : -1))
          .map((notificationTrigger, index) => (
            <div key={index}>
              <div>
                {notificationTrigger.operationTypeName ? (
                  `Content is ${formatOperationType(notificationTrigger.operationTypeName)}:`
                ) : (
                  <span>Op. ID: {notificationTrigger.operationTypeId} (nameless)</span>
                )}
              </div>
              <div>
                {notificationTrigger.emailTriggerEditable && (
                  <input
                    type="checkbox"
                    checked={notificationTrigger.emailTriggerEnabled}
                    disabled={isEmailOptedOut}
                    onChange={(e) => handleChange(e.target.checked, 'emailTriggerEnabled', notificationTrigger)}
                  />
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default UserNotificationModuleOption;
